<!--  -->
<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt />
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList" />
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt="" />
            <span>热门游戏</span>
             <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>
          <ul>
            <li :class="{ active: isShow == 0 }" @click="toClick(0)">首页</li>
            <li :class="{ active: isShow == 1 }" @click="toClick(1)">爱租号</li>
            <li :class="{ active: isShow == 2 }" @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
        <div class="contant-bj"></div>

    <div class="about">
      <div class="about-contaner">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }"
            >爱租号首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>爱租号介绍</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="about-img">
            <span>爱租号</span>
            <img src="../../assets/images/tp.png" alt="">
        </div>
        <div class="about-txt">
            <div class="title">公司简介</div>
            <p>爱租号是一家国内专注于游戏服务的平台，拥有全平台热门游戏的高端账号。在互联网凭借其独有的创新理念和平台反外挂安全技术，为账号主们提供优质的服务，做好链接用户与号主桥梁，充分保障账号安全，同时全面整合行业资源，聚焦为用户提供高品质的游戏体验。</p>
            <p style="margin:20px 0;">爱租号平台提供游戏账号道具体验，旨在帮助游戏用户用低廉的价格体验高端游戏账号，之后再决定是否购买某一游戏道具。从而降低用户的游戏消费门槛。</p>
            <p>
            爱租号将以优质的服务和体验为核心，我们将不忘初心继续打磨技术并创新产品，为玩家带来更好的体验而不懈努力。
            </p>                  
        </div>
      </div>
    </div>
    <!-- 电梯导航 -->
    <side-Bar></side-Bar>
  </layout>
</template>
 
<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import hotlist from '../../components/hotlist'

export default {
  name: "about",
  data() {
    return {
      valueList: '',
      isShow: 1
    };
  },
  components: {
    layout,
    sideBar,
    hotlist
  },
  created() {
  
  },
  mounted() {},
  methods: {
    toClick(id) {
      if (id == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/download",
        });
      } else {
        this.$router.push({
          path: "/lobby",
        });
      }
      this.isShow = id;
    },
   goSerach() {
        if(this.valueList){
            this.$router.push({
            path: '/lobby',
            query: {
            key_word: this.valueList
            }
          })
        }
      
    },
  },
};
</script>

<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
          position: relative;
          cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
          .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
         .active{
                   color: #000;
                   font-weight: bold;
                 }
        .active:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
.contant-bj{
    height: 15px;
  background-color: #f0f2f5;

}
//
.about {
  width: 100%;
  padding-bottom: 66px;
//   background-color: #f0f2f5;
  background-color: #fff;
  .about-contaner {
    width: 1200px;
    margin: 0 auto;
    .el-breadcrumb {
      padding: 25px 0;
    }
    .about-img{
        span{
            
font-size: 36px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #000000;
        }
        img{
            width: 100%;
            height: 100%;
            display: block;
            margin: 32px 0;
        }
    }
    .about-txt{
        .title{
            
font-size: 20px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #000000;
        }
        p{
            
font-size: 13px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #83899D;
line-height: 36px;
        }
    }
  }
}
</style>